<template>
  <div class="city_medium-box">
    <img v-for="(item, index) of list" :key="index" :src="item.img" :alt="item.name" class="card" @click="toPage(item)">
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [
          {
            name: '集贤微言',
            img: require('../../assets/images/medium_1.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAxMDEyNDk5MQ==&scene=110#wechat_redirect'
          },
          {
            name: '微看宝清',
            img: require('../../assets/images/medium_3.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3NjEyMjYwOA==&scene=110#wechat_redirect'
          },
          {
            name: '友谊在线',
            img: require('../../assets/images/medium_4.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI5MDQyMTE4Nw==&scene=110#wechat_redirect'
          },
          {
            name: '关注饶河',
            img: require('../../assets/images/medium_5.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3NzI4MzQxNQ==&scene=110#wechat_redirect'
          },
          {
            name: '微观尖山',
            img: require('../../assets/images/medium_6.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI4ODA1MzAyMA==&scene=110#wechat_redirect'
          },
          {
            name: '微视岭东',
            img: require('../../assets/images/medium_7.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA3MTc2NTgyOA==&scene=110#wechat_redirect'
          },
          {
            name: '四方台发布',
            img: require('../../assets/images/medium_8.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI4ODU3OTI5Nw==&scene=110#wechat_redirect'
          },
          {
            name: '观点宝山',
            img: require('../../assets/images/medium_9.png'),
            url: 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU2NzgzMTUxMg==&scene=110#wechat_redirect'
          }
        ]
      }
    },
    methods: {
      toPage(item) {
        window.open(item.url)
      }
    }
  }
</script>

<style lang="less" scoped>
.city_medium-box {
  padding: 16px;
  .card {
    width: 100%;
    object-fit: contain;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>